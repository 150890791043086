@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neoutils.neoregex.core.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val contrast: DrawableResource by 
      lazy { init_contrast() }

  public val dark_theme: DrawableResource by 
      lazy { init_dark_theme() }

  public val flavicon: DrawableResource by 
      lazy { init_flavicon() }

  public val github: DrawableResource by 
      lazy { init_github() }

  public val ic_launcher: DrawableResource by 
      lazy { init_ic_launcher() }

  public val ic_redo_24: DrawableResource by 
      lazy { init_ic_redo_24() }

  public val ic_undo_24: DrawableResource by 
      lazy { init_ic_undo_24() }

  public val light_theme: DrawableResource by 
      lazy { init_light_theme() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("contrast", CommonMainDrawable0.contrast)
  map.put("dark_theme", CommonMainDrawable0.dark_theme)
  map.put("flavicon", CommonMainDrawable0.flavicon)
  map.put("github", CommonMainDrawable0.github)
  map.put("ic_launcher", CommonMainDrawable0.ic_launcher)
  map.put("ic_redo_24", CommonMainDrawable0.ic_redo_24)
  map.put("ic_undo_24", CommonMainDrawable0.ic_undo_24)
  map.put("light_theme", CommonMainDrawable0.light_theme)
}

public val Res.drawable.contrast: DrawableResource
  get() = CommonMainDrawable0.contrast

private fun init_contrast(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:contrast",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/contrast.xml", -1, -1),
    )
)

public val Res.drawable.dark_theme: DrawableResource
  get() = CommonMainDrawable0.dark_theme

private fun init_dark_theme(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:dark_theme",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/dark_theme.xml", -1, -1),
    )
)

public val Res.drawable.flavicon: DrawableResource
  get() = CommonMainDrawable0.flavicon

private fun init_flavicon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:flavicon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/flavicon.png", -1, -1),
    )
)

public val Res.drawable.github: DrawableResource
  get() = CommonMainDrawable0.github

private fun init_github(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/github.xml", -1, -1),
    )
)

public val Res.drawable.ic_launcher: DrawableResource
  get() = CommonMainDrawable0.ic_launcher

private fun init_ic_launcher(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/ic_launcher.png", -1, -1),
    )
)

public val Res.drawable.ic_redo_24: DrawableResource
  get() = CommonMainDrawable0.ic_redo_24

private fun init_ic_redo_24(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_redo_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/ic_redo_24.xml", -1, -1),
    )
)

public val Res.drawable.ic_undo_24: DrawableResource
  get() = CommonMainDrawable0.ic_undo_24

private fun init_ic_undo_24(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_undo_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/ic_undo_24.xml", -1, -1),
    )
)

public val Res.drawable.light_theme: DrawableResource
  get() = CommonMainDrawable0.light_theme

private fun init_light_theme(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:light_theme",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/drawable/light_theme.xml", -1, -1),
    )
)
