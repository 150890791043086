package com.neoutils.neoregex

import kotlin.Int
import kotlin.OptIn
import kotlin.String
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@OptIn(ExperimentalJsExport::class)
public object NeoConfig {
  public val version: String = "2.5.0"

  public val code: Int = 250
}
