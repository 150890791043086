@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neoutils.neoregex.core.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val about_description_text: StringResource by 
      lazy { init_about_description_text() }

  public val about_runtime_text: StringResource by 
      lazy { init_about_runtime_text() }

  public val about_version_text: StringResource by 
      lazy { init_about_version_text() }

  public val about_vm_text: StringResource by 
      lazy { init_about_vm_text() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val fatal_error_copied_label: StringResource by 
      lazy { init_fatal_error_copied_label() }

  public val fatal_error_copy_btn: StringResource by 
      lazy { init_fatal_error_copy_btn() }

  public val fatal_error_message: StringResource by 
      lazy { init_fatal_error_message() }

  public val fatal_error_report_btn: StringResource by 
      lazy { init_fatal_error_report_btn() }

  public val fatal_error_report_tab: StringResource by 
      lazy { init_fatal_error_report_tab() }

  public val fatal_error_stack_trace_tab: StringResource by 
      lazy { init_fatal_error_stack_trace_tab() }

  public val fatal_error_subtitle: StringResource by 
      lazy { init_fatal_error_subtitle() }

  public val fatal_error_title: StringResource by 
      lazy { init_fatal_error_title() }

  public val insert_regex_hint: StringResource by 
      lazy { init_insert_regex_hint() }

  public val matcher_footer_insert_regex_hint: StringResource by 
      lazy { init_matcher_footer_insert_regex_hint() }

  public val web_warning_text: StringResource by 
      lazy { init_web_warning_text() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("about_description_text", CommonMainString0.about_description_text)
  map.put("about_runtime_text", CommonMainString0.about_runtime_text)
  map.put("about_version_text", CommonMainString0.about_version_text)
  map.put("about_vm_text", CommonMainString0.about_vm_text)
  map.put("app_name", CommonMainString0.app_name)
  map.put("fatal_error_copied_label", CommonMainString0.fatal_error_copied_label)
  map.put("fatal_error_copy_btn", CommonMainString0.fatal_error_copy_btn)
  map.put("fatal_error_message", CommonMainString0.fatal_error_message)
  map.put("fatal_error_report_btn", CommonMainString0.fatal_error_report_btn)
  map.put("fatal_error_report_tab", CommonMainString0.fatal_error_report_tab)
  map.put("fatal_error_stack_trace_tab", CommonMainString0.fatal_error_stack_trace_tab)
  map.put("fatal_error_subtitle", CommonMainString0.fatal_error_subtitle)
  map.put("fatal_error_title", CommonMainString0.fatal_error_title)
  map.put("insert_regex_hint", CommonMainString0.insert_regex_hint)
  map.put("matcher_footer_insert_regex_hint", CommonMainString0.matcher_footer_insert_regex_hint)
  map.put("web_warning_text", CommonMainString0.web_warning_text)
}

public val Res.string.about_description_text: StringResource
  get() = CommonMainString0.about_description_text

private fun init_about_description_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_description_text", "about_description_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 101,
    146),
    )
)

public val Res.string.about_runtime_text: StringResource
  get() = CommonMainString0.about_runtime_text

private fun init_about_runtime_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_runtime_text", "about_runtime_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 248, 54),
    )
)

public val Res.string.about_version_text: StringResource
  get() = CommonMainString0.about_version_text

private fun init_about_version_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:about_version_text", "about_version_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 303, 42),
    )
)

public val Res.string.about_vm_text: StringResource
  get() = CommonMainString0.about_vm_text

private fun init_about_vm_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about_vm_text", "about_vm_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 346, 45),
    )
)

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 392, 28),
    )
)

public val Res.string.fatal_error_copied_label: StringResource
  get() = CommonMainString0.fatal_error_copied_label

private fun init_fatal_error_copied_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_copied_label", "fatal_error_copied_label",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 421, 44),
    )
)

public val Res.string.fatal_error_copy_btn: StringResource
  get() = CommonMainString0.fatal_error_copy_btn

private fun init_fatal_error_copy_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_copy_btn", "fatal_error_copy_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 466, 44),
    )
)

public val Res.string.fatal_error_message: StringResource
  get() = CommonMainString0.fatal_error_message

private fun init_fatal_error_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_message", "fatal_error_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 511,
    111),
    )
)

public val Res.string.fatal_error_report_btn: StringResource
  get() = CommonMainString0.fatal_error_report_btn

private fun init_fatal_error_report_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_report_btn", "fatal_error_report_btn",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 623, 46),
    )
)

public val Res.string.fatal_error_report_tab: StringResource
  get() = CommonMainString0.fatal_error_report_tab

private fun init_fatal_error_report_tab(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_report_tab", "fatal_error_report_tab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 670, 46),
    )
)

public val Res.string.fatal_error_stack_trace_tab: StringResource
  get() = CommonMainString0.fatal_error_stack_trace_tab

private fun init_fatal_error_stack_trace_tab(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_stack_trace_tab", "fatal_error_stack_trace_tab",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 717, 51),
    )
)

public val Res.string.fatal_error_subtitle: StringResource
  get() = CommonMainString0.fatal_error_subtitle

private fun init_fatal_error_subtitle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_subtitle", "fatal_error_subtitle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 769, 64),
    )
)

public val Res.string.fatal_error_title: StringResource
  get() = CommonMainString0.fatal_error_title

private fun init_fatal_error_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fatal_error_title", "fatal_error_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 834, 49),
    )
)

public val Res.string.insert_regex_hint: StringResource
  get() = CommonMainString0.insert_regex_hint

private fun init_insert_regex_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:insert_regex_hint", "insert_regex_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 884, 57),
    )
)

public val Res.string.matcher_footer_insert_regex_hint: StringResource
  get() = CommonMainString0.matcher_footer_insert_regex_hint

private fun init_matcher_footer_insert_regex_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matcher_footer_insert_regex_hint", "matcher_footer_insert_regex_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 942, 72),
    )
)

public val Res.string.web_warning_text: StringResource
  get() = CommonMainString0.web_warning_text

private fun init_web_warning_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:web_warning_text", "web_warning_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neoutils.neoregex.core.resources/values/strings.commonMain.cvr", 1015,
    72),
    )
)
